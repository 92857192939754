"use client";

import { cn } from "~/utils/cn";
import { AdsKind, AdsSize, AdvertisementProps } from "./advertisement.types";

export const Advertisement: React.FC<AdvertisementProps> = ({
  size = AdsKind.medium,
  className,
}) => {
  return (
    <div
      className={cn(
        className,
        "w-full items-center justify-center overflow-hidden rounded-lg bg-white py-2",
      )}
    >
      {size === AdsKind.small && (
        <sellwild-ad
          height={AdsSize.small.height}
          width={AdsSize.small.width}
        />
      )}
      {size === AdsKind.medium && (
        <sellwild-ad
          height={AdsSize.medium.height}
          width={AdsSize.medium.width}
        />
      )}
      {size === AdsKind.large && (
        <sellwild-ad
          height={AdsSize.large.height}
          width={AdsSize.large.width}
        />
      )}
    </div>
  );
};
